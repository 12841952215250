import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs/index';
// import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import 'rxjs/add/operator/map';

export interface Collection {
  collection_id: number;
  collection_name: string;
  collection_descr: string;
}
export interface Gallery {
  artwork_id: number;
  collection_id: number;
  artwork_title: string;
  artwork_description: string;
  artwork_price: string;
  artwork_date: string;
  artwork_availability: string;
  artwork_img: string;
}
export interface New {
  new_id: number;
  new_title: number;
  new_description: string;
  new_like: number;
  new_img: string;
  user_name: string;
}
@Injectable({
  providedIn: 'root'
})
export class PublicService {

  collectionsUrl = 'http://www.igadget.esy.es/assets/php/collections.php?';
   api_url: 'http://www.igadget.esy.es/assets/php/';
  constructor(private  http: HttpClient) { }
  getCollections(): Observable <Collection> {
    return this.http.get<Collection>(this.collectionsUrl, {responseType: 'json'}); // TODO maybe don't work with interface
     // .map(res => res.toString() );
  }
  getGallery(collection_id): Observable<Gallery> {
    // return this.http.get(this.loginurl);
    return this.http.get<Gallery>('http://www.igadget.esy.es/assets/php/gallery.php?collection_id=' + collection_id);
  }
  getNews(): Observable<New> {
    // return this.http.get(this.loginurl);
    return this.http.get<New>('http://www.igadget.esy.es/assets/php/news.php?');
  }
  contactMessage(email, phone, subject, message) { // TODO
    return this.http.post('http://www.igadget.esy.es/assets/php/contact.php?email='
      + email + '&phone=' + phone + '&subject=' + subject + '&message=' + message, {
      headers: new HttpHeaders().append('AUTH_TEST', 'contact')
    });
  }
}
