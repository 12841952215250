import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location} from '@angular/common';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  title = 'app';
  showFiller = false; // TODO
  route: string;
  white_menu = false;

  constructor(location: Location,
              router: Router) {
    router.events.subscribe((val) => { // get the actual root
      if ( location.path() !== '') {
        this.route = location.path();
        console.log(this.route); // TODO

        if ( this.route !== '/home') {
          this.white_menu = true;
        } else if ( this.route === '/home') { // If route isn't home, white menu always displays
          this.white_menu = false;
          console.log(this.route);
        }
      }


      console.log(this.white_menu);
    });
  }

  ngOnInit() {
  }

}
