import { Component, OnInit } from '@angular/core';
import { PublicService} from '../../services/public.service';
import {Router, RouterLink, RouterModule} from "@angular/router";
@Component({
  selector: 'app-gallery',
 // providers: [PublicService],
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css'],
})
export class GalleryComponent implements OnInit {
  public gallery;
  public collections: any [] = [];
  constructor(private router: Router
  ) {
  }

  ngOnInit() {
    this.collections = [
      {'img': 'assets/collections/collections_preview/courts.jpg', 'id': 'Courts',
        'alt': 'Courts preview image', 'route': 'courts'},
      {'img': 'assets/collections/collections_preview/dreams.jpg', 'id': 'Dreams',
        'alt': 'Dreams preview image', 'route': 'dreams'},
      {'img': 'assets/collections/collections_preview/taiga.JPG', 'id': 'Taiga',
        'alt': 'Taiga preview image', 'route': 'taiga'},
      {'img': 'assets/collections/collections_preview/icons01.jpeg', 'id': 'Iconas',
        'alt': 'Iconas preview image', 'route': 'iconas'},
      {'img': 'assets/collections/collections_preview/abstract.jpg', 'id': 'Abstract',
        'alt': 'Abstract preview image', 'route': 'abstract'},
      {'img': 'assets/collections/collections_preview/sketch.JPG', 'id': 'Sketches',
        'alt': 'Sketch preview image', 'route': 'sketches'},
      {'img': 'assets/collections/collections_preview/portrait01.jpg', 'id': 'Portraits',
        'alt': 'Portraits preview image', 'route': 'portraits'},
      {'img': 'assets/collections/collections_preview/other01.jpg', 'id': 'Flowers',
        'alt': 'Others preview image', 'route': 'flowers'},
      {'img': 'assets/collections/collections_preview/Putin.jpg', 'id': 'Others',
        'alt': 'Others preview image', 'route': 'others'},
    ];
  }
  navigateToCollection(collectionRoute: string){
    this.router.navigate(['../collection', collectionRoute]);
  }
}
