import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';

// Material Design
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
// import { MaterialModule } from './material.module';
import {
  MatToolbarModule,
  MatTabsModule,
  MatButtonModule,
  MatIconModule,
  MatCardModule,
  MatSidenavModule,
  MatGridListModule,
   MatListModule,
  MatExpansionModule,
  MatInputModule,
  MatDialogModule,
  MatFormFieldModule,
  MatStepperModule
} from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// NgxBootstrap component
import { CarouselModule } from 'ngx-bootstrap/carousel';

// Routing
import { AppRoutingModule } from './app-routing.module';
import {Router} from '@angular/router';

// Components
import { GalleryComponent } from './pages/gallery/gallery.component';
import { ButtonMenuComponent} from './components/button-menu/button-menu.component';
import { HomeComponent } from './pages/home/home.component';
import { MenuComponent } from './components/menu/menu.component';
import { ExhibitionsComponent } from './pages/exhibitions/exhibitions.component';
import { NewsComponent} from './pages/news/news.component';
import {ContactComponent} from './pages/contact/contact.component';
import {CollectionComponent} from './pages/collection/collection.component';
import { LoginComponent } from './pages/login/login.component';
import {AddnewComponent} from './components/addnew/addnew.component';

// Peticiones
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {LayoutModule} from '@angular/cdk/layout';
import { HttpModule, JsonpModule } from '@angular/http';


// Services
import {UserService} from './services/user.service';
import {PublicService} from './services/public.service';

// Interceptor
import { AuthentificationInterceptor} from './authentification.interceptor';
import {TokenService} from './services/token.service';


// import './rxjs-operators';

@NgModule({
  declarations: [
    AppComponent,
    GalleryComponent,
    ButtonMenuComponent,
    HomeComponent,
    MenuComponent,
    ExhibitionsComponent,
    NewsComponent,
    ContactComponent,
    CollectionComponent,
    LoginComponent,
    AddnewComponent
  ],
  imports: [
    BrowserModule,
   // MaterialModule,
    BrowserAnimationsModule,
    FormsModule,
    MatSidenavModule,
    AppRoutingModule,
    MatToolbarModule,
    MatTabsModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    MatListModule,
    LayoutModule,
    MatGridListModule,
    MatExpansionModule,
    MatDialogModule,
    MatFormFieldModule,
    MatStepperModule,
    HttpClientModule,
    MatInputModule,
   // Ng2CarouselamosModule
    CarouselModule.forRoot(),
  ],
  providers: [
    UserService,
    PublicService,
    TokenService,
    {
     provide: HTTP_INTERCEPTORS,
     useClass: AuthentificationInterceptor,
     multi: true
    }
    ], // services here
  bootstrap: [AppComponent],
 })
export class AppModule {
  // Diagnostic only: inspect router configuration TODO
  constructor(router: Router) {
    console.log('Routes: ', JSON.stringify(router.config, undefined, 2));
  }
}
