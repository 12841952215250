import { Component, OnInit } from '@angular/core';
import {UserService} from '../../services/user.service';


@Component({
  selector: 'app-addnew',
  providers: [UserService],
  templateUrl: './addnew.component.html',
  styleUrls: ['./addnew.component.css']
})
export class AddnewComponent implements OnInit {
  new_title: '';
  new_description: '';
  new_img: string;
  username: '';
  private token;
  private name;
  private error: string;

  constructor(private _userService: UserService) {
    this.name = localStorage.getItem('username');
    this.token = localStorage.getItem('token');
  }

  ngOnInit() {
  }

  public addNewPost(new_title: string, new_description: string) {

    if (new_title === undefined || new_description === undefined) {
      this.error = 'Debe rellenar todos los campos';
    } else {
      this._userService.addNew(new_title, new_description, this.token).subscribe(res => {
          console.log('post añadido' + res);
          console.log('datos' + new_title + new_description);
        },
        err => {
          this.error = 'Ha ocurrido un error publicando este Post';
        });
    }
  }

}
