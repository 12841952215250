import { Component, OnInit } from '@angular/core';
import {PublicService} from '../../services/public.service';

@Component({
  selector: 'app-news',
  providers: [PublicService],
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {
  panelOpenState = false;
  private news;
  public  token;
  constructor(private _publicService: PublicService) { }

  ngOnInit() {
    this.getNews();
    this.token = localStorage.getItem('token');
  }
// get news_id, title, likes, img, description, username
  public getNews() {
    this._publicService.getNews()
      .subscribe(data => {
        this.news = data;
      });
  }
}
