export class TokenService {
  private TOKEN = '';
  private key: '';
  getToken() {
    this.TOKEN = localStorage.getItem(this.key);
    console.log('id autentification:' + this.TOKEN);
    if (this.TOKEN == null) {
      console.log('no authentificated:' + this.TOKEN);
    } else if (this.TOKEN != null) {
      console.log('authentificated:' + this.TOKEN);
    }
    return this.TOKEN;
  }
}
