export const COLLECTION_DESCR = {
  'dreams': {
    'title': 'Spend a day in your favourite city',
    'description':'“I don’t like white, in my works I try to get away from it\n' +
      'neutrality and inexpressiveness ... I remember, in childhood, we\n' +
      '\n' +
      'at home hung reproductions of a triptych by P. Uccello, the Battle of San\n' +
      'Romanes, 1456, they made me a real\n' +
      '\n' +
      'dramatic effect, I was just hypnotized by such\n' +
      'extraordinary use of colors and light: blue and\n' +
      'red horses, dark background..."'
  }
};
export const COLLECTION = {
  'taiga' : [
    {'img': 'assets/collections/taiga/1_1.JPG',
      'alt': 'painting photo',
      'title': ' Musa acuminata',
      'date': '2014',
      'size': '17cm x 33cm',
      'material': 'acrylic on wood',
      'price': 'private collection',
      'vertical': false
    },
    {'img': 'assets/collections/taiga/044.JPG',
      'alt': 'painting photo',
      'title': 'Eucalyptus',
      'date': '2014',
      'size': '17cm x 33cm',
      'material': 'acrylic on wood',
      'price': 'private collection',
      'vertical': false
    },
    {'img': 'assets/collections/taiga/0049.JPG',
      'alt': 'painting photo',
      'title': 'Taiga',
      'date': '2014',
      'size': '17cm x 33cm',
      'material': 'acrylic on wood',
      'price': 'private collection',
      'vertical': false
    },
    {'img': 'assets/collections/taiga/0074.JPG',
      'alt': 'painting photo',
      'title': 'Blue Cactus',
      'date': '2014',
      'size': '17cm x 33cm',
      'material': 'acrylic on wood',
      'price': 'private collection',
      'vertical': false
    },
    {'img': 'assets/collections/taiga/0077.JPG',
      'alt': 'painting photo',
      'title': 'Olive-tree',
      'date': '2014',
      'size': '17cm x 33cm',
      'material': 'acrylic on wood',
      'price': 'private collection',
      'vertical': false
    },
    {'img': 'assets/collections/taiga/888.JPG',
      'alt': 'painting photo',
      'title': 'Taiga - 2',
      'date': '2014',
      'size': '17cm x 33cm',
      'material': 'acrylic on wood',
      'price': 'private collection',
      'vertical': false
    },
    {'img': 'assets/collections/taiga/7777.jpg',
      'alt': 'painting photo',
      'title': 'Mist',
      'date': '2014',
      'size': '17cm x 33cm',
      'material': 'acrylic on wood',
      'price': 'private collection',
      'vertical': false
    },
    {'img': 'assets/collections/taiga/9090.jpg',
      'alt': 'painting photo',
      'title': 'Eucalyptus - 2',
      'date': '2015',
      'size': '17cm x 33cm',
      'material': 'acrylic on wood',
      'price': 'private collection',
      'vertical': false
    },
    {'img': 'assets/collections/taiga/111.jpg',
      'alt': 'painting photo',
      'title': 'Sunset in the mountains',
      'date': '2015',
      'size': '17cm x 33cm',
      'material': 'acrylic on wood',
      'price': 'private collection',
      'vertical': true
    },
    {'img': 'assets/collections/taiga/8_2.JPG',
      'alt': 'painting photo',
      'title': '-',
      'date': '2015',
      'size': '17cm x 33cm',
      'material': 'acrylic on wood',
      'price': 'private collection',
      'vertical': true
    },
  ],

   'courts' : [
    {'img': '../../assets/collections/squares/4.jpg',
      'id': '01',
      'alt': 'painting photo',
      'title': 'Courtyards of  Sretenka',
      'date': '2014',
      'size': '60cm x 92cm',
      'material': 'acrylic on canvas',
      'price': 'private collection',
      'vertical': false
    },
     {'img': '../../assets/collections/squares/5_2.jpg',
       'id': '01',
       'alt': 'painting photo',
       'title': 'Boulevard',
       'date': '2012',
       'size': '89cm x 130cm',
       'material': 'acrylic on canvas',
       'price': 'private collection',
       'vertical': false
     },
     {'img': '../../assets/collections/squares/0120.jpg',
       'id': '01',
       'alt': 'painting photo',
       'title': 'Immersion - 1',
       'date': '2016',
       'size': '89cm x 119cm',
       'material': 'acrylic on wood',
       'price': '4000€',
       'vertical': false
     },
     {'img': '../../assets/collections/squares/e.jpg',
       'id': '01',
       'alt': 'painting photo',
       'title': 'Courtyard',
       'date': '2017',
       'size': '30cm x 40cm',
       'material': 'acrylic on canvas',
       'price': 'private collection',
       'vertical': false
     },
     {'img': '../../assets/collections/squares/j.jpg',
       'id': '01',
       'alt': 'painting photo',
       'title': 'La Dona',
       'date': '2010',
       'size': '94cm x 60cm',
       'material': 'acrylic on canvas',
       'price': 'private collection',
       'vertical': true
     },
     {'img': '../../assets/collections/squares/k.jpg',
       'id': '01',
       'alt': 'painting photo',
       'title': 'Red roofs',
       'date': '2009',
       'size': '38cm x 150cm',
       'material': 'acrylic on canvas',
       'price': 'private collection',
       'vertical': false
     },
     {'img': '../../assets/collections/squares/l.jpg',
       'id': '01',
       'alt': 'painting photo',
       'title': 'Yellow mist',
       'date': '2012',
       'size': '38cm x 150cm',
       'material': 'acrylic on canvas',
       'price': 'private collection',
       'vertical': false
     }
  ],

 'dreams' : [
    {'img': 'assets/collections/dreams/1.jpg',
      'id': '01',
      'alt': 'painting photo',
      'title': 'One less tree',
      'date': '2018-2020',
      'size': '113cm x 148cm',
      'material': 'acrylic on canvas',
      'price': 'private collection',
      'vertical': false
    },
   {'img': 'assets/collections/dreams/2.jpg',
     'id': '01',
     'alt': 'painting photo',
     'title': 'Immersion - 2',
     'date': '2016-2018',
     'size': '113cm x 148cm',
     'material': 'acrylic on canvas',
     'price': 'private collection',
     'vertical': false
   },
   {'img': 'assets/collections/dreams/3.jpg',
     'id': '01',
     'alt': 'painting photo',
     'title': 'Immersion - 3',
     'date': '2016-2018',
     'size': '113cm x 148cm',
     'material': 'acrylic on canvas',
     'price': 'private collection',
     'vertical': false
   },
   {'img': 'assets/collections/dreams/b.JPG',
     'id': '01',
     'alt': 'painting photo',
     'title': 'Apocalypse',
     'date': '2016',
     'size': '60cm x 92cm',
     'material': 'acrylic on canvas',
     'price': 'private collection',
     'vertical': true
   },
  /* {'img': 'assets/collections/dreams/f.jpg',
     'id': '01',
     'alt': 'painting photo',
     'title': 'Yellow mist',
     'date': '2012',
     'size': '38cm x 150cm',
     'material': 'acrylic on canvas',
     'price': 'private collection',
     'vertical': false
   }*/
  ],

  'iconas' : [
    {'img': 'assets/collections/iconas/icons01.jpeg',
      'id': '01',
      'alt': 'painting photo',
      'title': 'Holy Trinity',
      'date': '2014 - 2020',
      'size': '140cm x 190cm',
      'material': 'acrylic on canvas',
      'price': 'private collection',
      'vertical': false
    },
    /*{'img': 'assets/collections/iconas/5.jpg',
      'id': '01',
      'alt': 'painting photo',
      'title': 'Archangel Michael',
      'date': '2014 - 2020',
      'size': '28cm x 100cm',
      'material': 'acrylic on wood',
      'price': 'private collection',
      'vertical': false
    },*/
    {'img': 'assets/collections/iconas/Icon1.jpg',
      'id': '01',
      'alt': 'painting photo',
      'title': 'Archangel Michael ',
      'date': '2014 - 2020',
      'size': '28cm x 100cm',
      'material': 'acrylic on wood',
      'price': 'private collection',
      'vertical': true
    },
    {'img': 'assets/collections/iconas/SantJoan2.jpg',
      'id': '01',
      'alt': 'painting photo',
      'title': 'San Joann Baptista',
      'date': '2014 - 2020',
      'size': '28cm x 38cm',
      'material': 'acrylic on wood',
      'price': 'private collection',
      'vertical': true
    },
    {'img': 'assets/collections/iconas/Verge1.jpg',
      'id': '01',
      'alt': 'painting photo',
      'title': 'Archangel Michael ',
      'date': '2014 - 2020',
      'size': '28cm x 38cm',
      'material': 'acrylic on wood',
      'price': 'private collection',
      'vertical': true
    },
    {'img': 'assets/collections/iconas/Crist2.jpg',
      'id': '01',
      'alt': 'painting photo',
      'title': 'Spas',
      'date': '2014 - 2020',
      'size': '28cm x 38cm',
      'material': 'acrylic on wood',
      'price': 'private collection',
      'vertical': true
    },
    {'img': 'assets/collections/iconas/m.jpg',
      'id': '01',
      'alt': 'painting photo',
      'title': 'Archangel Michael"',
      'date': '2014 - 2020',
      'size': '28cm x 100cm',
      'material': 'acrylic on wood',
      'price': 'private collection',
      'vertical': true
    },
  ],
  'abstract' : [
    {'img': 'assets/collections/abstract/a.jpg',
      'id': '01',
      'alt': 'painting photo',
      'title': 'Planeta K-Rah',
      'date': '2015',
      'size': '45cm x 45cm',
      'material': 'acrylic on canvas',
      'price': 'private collection',
      'vertical': false
    },
    {'img': 'assets/collections/abstract/i.jpg',
      'id': '01',
      'alt': 'painting photo',
      'title': 'Inmersion - 3',
      'date': '2013',
      'size': '40cm x 40cm',
      'material': 'acrylic on canvas',
      'price': 'private collection',
      'vertical': false
    },
    {'img': 'assets/collections/abstract/c.jpg',
      'id': '01',
      'alt': 'painting photo',
      'title': 'Sukharev Tower',
      'date': '2016',
      'size': '45cm x 45cm',
      'material': 'acrylic on canvas',
      'price': 'private collection',
      'vertical': false
    },
    {'img': 'assets/collections/abstract/7.jpg',
      'id': '01',
      'alt': 'painting photo',
      'title': 'Self portrait',
      'date': '2015',
      'size': '60cm x 92cm',
      'material': 'acrylic on canvas',
      'price': 'private collection',
      'vertical': false
    }
  ],
  'sketches' : [
    {'img': 'assets/collections/sketches/000.jpg',
      'id': '01',
      'alt': 'painting photo',
      'title': 'Yellow mist',
      'date': '2012',
      'size': '38cm x 150cm',
      'material': 'acrylic on canvas',
      'price': 'private collection',
      'vertical': false
    },
    {'img': 'assets/collections/sketches/2.JPG',
      'id': '01',
      'alt': 'painting photo',
      'title': 'Yellow mist',
      'date': '2012',
      'size': '38cm x 150cm',
      'material': 'acrylic on canvas',
      'price': 'private collection',
      'vertical': false
    },
    {'img': 'assets/collections/sketches/2_2.jpg',
      'id': '01',
      'alt': 'painting photo',
      'title': 'Yellow mist',
      'date': '2012',
      'size': '38cm x 150cm',
      'material': 'acrylic on canvas',
      'price': 'private collection',
      'vertical': false
    },
    {'img': 'assets/collections/sketches/13.jpg',
      'id': '01',
      'alt': 'painting photo',
      'title': 'Yellow mist',
      'date': '2012',
      'size': '38cm x 150cm',
      'material': 'acrylic on canvas',
      'price': 'private collection',
      'vertical': false
    },
    {'img': 'assets/collections/sketches/32.JPG',
      'id': '01',
      'alt': 'painting photo',
      'title': 'Yellow mist',
      'date': '2012',
      'size': '38cm x 150cm',
      'material': 'acrylic on canvas',
      'price': 'private collection',
      'vertical': false
    },
    {'img': 'assets/collections/sketches/35.JPG',
      'id': '01',
      'alt': 'painting photo',
      'title': 'Yellow mist',
      'date': '2012',
      'size': '38cm x 150cm',
      'material': 'acrylic on canvas',
      'price': 'private collection',
      'vertical': false
    },
    {'img': 'assets/collections/sketches/66.JPG',
      'id': '01',
      'alt': 'painting photo',
      'title': 'Yellow mist',
      'date': '2012',
      'size': '38cm x 150cm',
      'material': 'acrylic on canvas',
      'price': 'private collection',
      'vertical': false
    },
    {'img': 'assets/collections/sketches/89.JPG',
      'id': '01',
      'alt': 'painting photo',
      'title': 'Yellow mist',
      'date': '2012',
      'size': '38cm x 150cm',
      'material': 'acrylic on canvas',
      'price': 'private collection',
      'vertical': false
    },
    {'img': 'assets/collections/sketches/img.png',
      'id': '01',
      'alt': 'painting photo',
      'title': 'Yellow mist',
      'date': '2012',
      'size': '38cm x 150cm',
      'material': 'acrylic on canvas',
      'price': 'private collection',
      'vertical': false
    }
  ],
  'portraits' : [
    {'img': 'assets/collections/portrait/portrait01.jpg',
      'id': '01',
      'alt': 'painting photo',
      'title': 'La Dona - 2',
      'date': '2012',
      'size': '38cm x 150cm',
      'material': 'acrylic on canvas',
      'price': 'private collection',
      'vertical': false
    }
  ],
  'flowers' : [
    {'img': 'assets/collections/flowers/other01.jpg',
      'id': '01',
      'alt': 'painting photo',
      'title': 'Yellow mist',
      'date': '2012',
      'size': '38cm x 150cm',
      'material': 'acrylic on canvas',
      'price': 'private collection',
      'vertical': false
    },
    {'img': 'assets/collections/flowers/other2.jpg',
      'id': '01',
      'alt': 'painting photo',
      'title': 'Yellow mist',
      'date': '2012',
      'size': '38cm x 150cm',
      'material': 'acrylic on canvas',
      'price': 'private collection',
      'vertical': false
    },
    {'img': 'assets/collections/flowers/other3.jpg',
      'id': '01',
      'alt': 'painting photo',
      'title': 'Yellow mist',
      'date': '2012',
      'size': '38cm x 150cm',
      'material': 'acrylic on canvas',
      'price': 'private collection',
      'vertical': false
    },
    {'img': 'assets/collections/flowers/other4.jpg',
      'id': '01',
      'alt': 'painting photo',
      'title': 'Yellow mist',
      'date': '2012',
      'size': '38cm x 150cm',
      'material': 'acrylic on canvas',
      'price': 'private collection',
      'vertical': false
    }],
  'others' : [
    {'img': 'assets/collections/other/Putin.jpg',
      'id': '01',
      'alt': 'painting photo',
      'title': 'Yellow mist',
      'date': '2012',
      'size': '38cm x 150cm',
      'material': 'acrylic on canvas',
      'price': 'private collection',
      'vertical': false
    },
    {'img': 'assets/collections/other/20211218_083901.jpg',
      'id': '01',
      'alt': 'painting photo',
      'title': 'Yellow mist',
      'date': '2012',
      'size': '38cm x 150cm',
      'material': 'acrylic on canvas',
      'price': 'private collection',
      'vertical': false
    },
    {'img': 'assets/collections/other/20220526_085710.jpg',
      'id': '01',
      'alt': 'painting photo',
      'title': 'Yellow mist',
      'date': '2012',
      'size': '38cm x 150cm',
      'material': 'acrylic on canvas',
      'price': 'private collection',
      'vertical': false
    },
    {'img': 'assets/collections/other/20220526_085820.jpg',
      'id': '01',
      'alt': 'painting photo',
      'title': 'Yellow mist',
      'date': '2012',
      'size': '38cm x 150cm',
      'material': 'acrylic on canvas',
      'price': 'private collection',
      'vertical': false
    },
  ]

};
