import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location} from '@angular/common';
import { setTheme } from 'ngx-bootstrap/utils';
import { HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  showFiller = false; // TODO
  route: string;
  white_btn = false; // TODO transparent menu on home
  transp_menu = false;
  header_title = ''; // h2
  header_subtitle = ''; // h3
  photo_toolbar = false;
  isLinear = false; // stepper
  instagram = false;
  facebook = false;

  constructor(location: Location,
              router: Router,
              private http: HttpClient) {
    setTheme('bs4'); // or 'bs4' // TODO maybe errors

    router.events.subscribe((val) => { // get the actual root
      if (location.path() !== '') {
        this.route = location.path();
        this.header_title = '';
        this.header_subtitle = '';
        this.photo_toolbar = false;

        if (this.route !== '/home') {
          this.white_btn = false;
          this.transp_menu = false;
          if (this.route === '/bio') {
            this.header_title = 'Biography'; // TODO translation
            this.header_subtitle = 'contemporary artist';
            // this.photo_toolbar = true;
            this.photo_toolbar = false;
          }
          if (this.route === '/gallery') {
            this.header_title = 'gallery';
            this.header_subtitle = 'sasha zimulin';
            this.photo_toolbar = false;
          }
          if (this.route === '/contact') {
            this.header_title = 'contact';
            this.header_subtitle = 'the artist';
            this.photo_toolbar = false;
          }
          if (this.route === '/exhibitions') {
            this.header_title = 'exhibitions';
            this.header_subtitle = 'sasha zimulin';
            this.photo_toolbar = false;
          }
          if (this.route.includes('collection')) {
            this.header_title = 'collection';
            this.header_subtitle = 'sasha zimulin';
            this.photo_toolbar = false;
          }
        }  else if (this.route === '/home') { // If route isn't home, white menu always displays
          this.white_btn = true;
          this.transp_menu = true;
          this.photo_toolbar = false;
          console.log(this.route);
        }
      }


      console.log(this.white_btn);
    });
  }
  ngOnInit(): void { // adding the lifecycle hook ngOnInit
   // this.http.get('http://jsonplaceholder.typicode.com/posts').subscribe(data => {
   // this.http.get('http://sashazimulin.com/users.php?').subscribe(data => {

    this.http.get('http://www.igadget.esy.es/assets/php/users.php?').subscribe(data => {
      });
    /*interface DataResponse {
      userId: string;
      id: string;
      title: string;
    }
    this.http.get<DataResponse>('http://jsonplaceholder.typicode.com/posts/1').subscribe(data => {
      console.log('UserId: ' + data.userId);
      console.log('Id: ' + data.id);
      console.log('Title: ' + data.title);
      console.log('Body: ' + data.body);
    });*/
  }
  public func_instagram()  {
   this.instagram = true;
    this.facebook = false;
  }
  public func_facebook()  {
    this.facebook = true;
    this.instagram = false;
  }
}

