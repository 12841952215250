import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  showIndicator = false;
  slides = [
   {image: 'assets/img/home.jpg'} // ,
    // {image: 'assets/img/homebackground.jpg'}
  ];
  constructor() { }

  ngOnInit() {
  }




}
