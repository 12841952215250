import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {COLLECTION, COLLECTION_DESCR} from './const';
interface Collection_description{
  title: string;
  description: string;
}

@Component({
 // encapsulation: ViewEncapsulation.Emulated,
  selector: 'app-collection',
  templateUrl: './collection.component.html',
  styleUrls: ['./collection.component.css'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class CollectionComponent implements OnInit {
  public collection: any[] = [];
  param: string;
  title = '';
  collection_descr: Collection_description;
  constructor(private route: ActivatedRoute) {
    this.route.params.subscribe((params) => {
      if ( params.id != null || !params.id ) {
        this.param = params.id;
        this.getCollectionByQueryParams(params.id);
      }
    });
  }
  ngOnInit() {
   // this.collection_descr = { title: '', description: '' };
   }
   getCollectionByQueryParams(param: string){
     switch(param) {
       case 'courts': {this.collection = COLLECTION.courts;
         this.title = 'Courts';
         break;
       }
       case 'dreams': { this.collection = COLLECTION.dreams;
         this.title = 'Dreams';
         this.collection_descr = COLLECTION_DESCR.dreams;
         break;
       }case 'taiga': {this.collection = COLLECTION.taiga;
         this.title = 'Taiga';
         break;
       }case 'iconas': { this.collection = COLLECTION.iconas;
         this.title = 'Iconas';
         break;
       }case 'abstract': { this.collection = COLLECTION.abstract;
         this.title = 'Abstract';
         break;
       }case 'sketches': { this.collection = COLLECTION.sketches;
         this.title = 'Sketches';
         break;
       }case 'portraits': { this.collection = COLLECTION.portraits;
         this.title = 'Portraits';
         break;
       }case 'flowers': { this.collection = COLLECTION.flowers;
         this.title = 'Flowers';
         break;
       }
       case 'others': { this.collection = COLLECTION.others;
         this.title = 'Others';
         break;
       }
       default: {break;}
     }

  }
}


