import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  api_url = 'http://www.igadget.esy.es/assets/php/';

  constructor(private  http: HttpClient) {
  }
  getUser(username, password) {
    return this.http.get(`${this.api_url}/users.php?username=` + username + '&password=' + password); // , {
  }
  addNew(title: string, description: string, token: string) {
    const headers = new HttpHeaders().append('AUTH_TOKEN', token);
    // console.log('ha llegado la info al service' + title + description);
    // return this.http.post('http://www.igadget.esy.es/assets/php/addnew.php?title=' + title + '&description=' + description, {
    return this.http.post(`${this.api_url}/addnew.php?title=` + title + '&description=' + description + '&token=' + token, {
      headers: new HttpHeaders().append('AUTH_TEST', token)});
  }
}
