import { Component, OnInit } from '@angular/core';
import { UserService} from '../../services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  public  users:  Array<object> = [];
  public  username: '';
  public  password: '';
  public  key: '';  // key local Storage
  public  user_id;
  public  token;
  public error: string;
  private name: string;
  private user: ''; // key local Storage
  constructor(private _userService: UserService) {
  }
  ngOnInit() {
      this.token = localStorage.getItem('token');
      this.name = localStorage.getItem('username');
  }

  public loginValidation(username: string , password: string) {
    console.log(username);
    if (username === undefined || password === undefined) {
      this.error = 'Debe rellenar todos los campos' ;
    } else {
      this.login(username, password); // http request
    }
  }
  public login(username, password) {
    this._userService.getUser(username, password)
      .subscribe((data: Array<Object>) => {
        this.users  =  data;
        let i;
        for ( i = 0 ; i < this.users.length; i++) {
          // console.log('username' + this.users[i]['user_name']);
          this.token = this.users[i]['token'];
          this.name = this.users[i]['user_name'];
          localStorage.setItem('token', this.users[i]['token']);
          localStorage.setItem('username', this.users[i]['user_name']);
        }
      });
  }
}
