import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {TokenService} from './services/token.service';
import {Injectable} from '@angular/core';
@Injectable ({
  providedIn: 'root'
})
export class AuthentificationInterceptor implements HttpInterceptor {

  constructor(private  _tokenServide: TokenService) {

  }
  // TODO
  intercept(req: HttpRequest<any>, next: HttpHandler ): Observable<HttpEvent<any>> {
    const clonedRequest = req.clone({
      // en cada peticion se añadira la fila
      headers: req.headers.set('AUTH_TOKEN', this._tokenServide.getToken()), // this._tokenServide.getToken()
    });

    return next.handle(clonedRequest);
  }
}
